<template>
  <div class="researchTrends">
      <div>
        <!-- autoplay interval='10000' -->
        <el-carousel height="400px" indicator-position='none' :autoplay='false'>
            <el-carousel-item>
                <a href="https://s.cyol.com/articles/2020-08/17/content_rbxNORcv.html?gid=28Dga1xp" target="_blank">
                <div class="box">
                    <img class="flie-position" src="../assets/hzal/2.jpg" alt="" ref="imgSize">
                    <div class="img_tit">
                        <p>天才在线教育专家宋洪峰就业公开课云直播 以“飞轮模型”激发青年潜能</p>
                        <div>中青报</div>
                    </div>             
                </div>
                </a>
            </el-carousel-item>
            <el-carousel-item>
              <a href="https://mp.weixin.qq.com/s/ZO4l6msd3Ouqu0ZGCy2AGw" target="_blank">
                <div class="box">
                    <img src="../assets/img/researchTrends_h6.jpg" alt="">
                    <div class="img_tit">
                        <p>天才在线新书《全面发展视域下高校第二课堂素质育人新解》发布</p>
                    </div>             
                </div>
              </a>
            </el-carousel-item>
        </el-carousel>
      </div>
      <div class="researchTrends_content">
          <a class="item" href="https://mp.weixin.qq.com/s/ZO4l6msd3Ouqu0ZGCy2AGw" target="_blank">
             <div class="item-img-box">
                  <img src="../assets/img/researchTrends_h6.jpg" alt="">
             </div>
              <p class="p1">天才在线新书《全面发展视域下高校第二课堂素质育人新解》发布</p>
              <p class="p2">2020-4-16</p>
          </a>
          <a class="item" href="https://mp.weixin.qq.com/s/8nQkYpZaLwTt2HzhRG4CFg" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/1.jpg" alt="">
           </div>
              <p class="p1">《青年能力素质成长飞轮》</p>
              <p class="p2">2019-12-5</p>
          </a>
          <a class="item" href="https://s.cyol.com/articles/2020-08/17/content_rbxNORcv.html?gid=28Dga1xp" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/2.jpg" alt="">
          </div>
              <p class="p1">天才在线教育专家宋洪峰就业公开课云直播 以“飞轮模型”激发青年潜能</p>
              <p class="p2">2019-12-5</p>
              <div class="item_tit">中青报</div>
          </a>
          <a class="item" href="http://digitalpaper.stdaily.com/http_www.kjrb.com/kjrb/html/2021-01/14/content_461120.htm?div=-1" target="_blank">
           <div class="item-img-box">
              <img src="../assets/hzal/3.jpg" alt="">
          </div>
              <p class="p1">天才在线助力高校开展第二课堂 五育并举开辟素质教育新天地</p>
              <p class="p2">2021-01-19</p>
              <div class="item_tit">科技日报</div>
          </a>
          <a class="item" href="http://d.youth.cn/newtech/202011/t20201125_12591430.htm" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/4.jpg" alt="">
          </div>
              <p class="p1">瞄准学科评估指挥棒 天才在线五育并举助力高校学科建设接受全面体检</p>
              <p class="p2">2020-11-25</p>
              <div class="item_tit">中国青年网</div>
          </a>
          <a class="item" href="http://www.cnr.cn/sxpd/ws/20201224/t20201224_525373580.shtml" target="_blank">
          <div class="item-img-box">
              <img src="../assets/img/xd2.jpg" alt="">
          </div>
              <p class="p1">天才在线CEO余晶莹作为专家受邀参与西电第二课堂调研活动</p>
              <p class="p2">2020-12-24</p>
              <div class="item_tit">中国广播网陕西</div>
          </a>
          <a class="item" href="https://biz.ifeng.com/c/7yn83ulpfs4" target="_blank">
          <div class="item-img-box">
              <img src="../assets/img/researchTrends_h1.png" alt="">
           </div>
              <p class="p1">天才在线五育并举：助力高校打造以素质育人为导向的第二课堂体系</p>
              <p class="p2">2020-08-10</p>
              <div class="item_tit">凤凰网</div>
          </a>
          <a class="item" href="https://tech.gmw.cn/2020-10/21/content_34291450.htm" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/5.png" alt="">
          </div>
              <p class="p1">天才在线五育并举：助力高校打造以素质育人为导向的第二课堂体系</p>
              <p class="p2">2020-10-21</p>
              <div class="item_tit">光明网</div>
          </a>
          <a class="item" href="http://news.cyol.com/app/2020-09/13/content_18775368.htm" target="_blank">
          <div class="item-img-box">
              <img src="../assets/img/researchTrends_h1.png" alt="">
          </div>
              <p class="p1">像拼乐高积木一样搭建第二课堂 服务超过150万大学生</p>
              <p class="p2">2020-9-13</p>
              <div class="item_tit">中国青年报</div>
          </a>
          <a class="item" href="https://mp.weixin.qq.com/s/E5e1_W9UL3yYSLfS41_HbA" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/6.jpg" alt="">
          </div>
              <p class="p1">新一轮评估重点审核项目：社会实践</p>
              <p class="p2">2021-2-13</p>
              <div class="item_tit">天才在线观点</div>
          </a>
          <a class="item" href="https://mp.weixin.qq.com/s/SlukZP-QFsmU-sXbr6wjrg" target="_blank">
          <div class="item-img-box">
              <img src="../assets/hzal/7.png" alt="">
           </div>
              <p class="p1">余晶莹总裁出席大学生劳动素养评价 研究座谈会</p>
              <p class="p2">2020-6-5</p>
              <div class="item_tit">天才在线</div>
          </a>
      </div>
  </div>
</template>

<script>
export default {
  name: 'researchTrends',
  data() {
      return {
      };
    },
    mounted(){
      this.$refs.imgSize.onload=function(){
        console.log(this.width);
        console.log(this.height);
      }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
.researchTrends{
  background: #fff;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  .box{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-self: center;
      img{
        width: 100%;
        position: absolute;
        top: 0;
        animation: myfirst 20s linear 1s infinite alternate;
        /* Firefox: */
        -moz-animation: myfirst 20s linear 1s infinite alternate;
        /* Safari 和 Chrome: */
        -webkit-animation: myfirst 20s linear 1s infinite alternate;
        /* Opera: */
        -o-animation: myfirst 20s linear 1s infinite alternate;
      }
  }
.img_tit{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(77,120,147,.6);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  0 60px;
    p{
      font-size: 28px;
      color: #fff;
    }
    div{
      background: #01C7D8;
      border-radius: 5px;
      font-size: 20px;
      font-weight: bold;
      padding: 7px 20px;
      min-width: 126px;
      color: #fff;
    }
}
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
  .researchTrends_content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 110px;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-wrap: wrap;
    padding: 0 71px;
    .item{
       width: 500px;
       background: #fff;
       box-shadow: 0 0 10px rgba(0,0,0,.1);
       border-radius: 10px;
       margin-bottom: 50px;
       cursor: pointer;
       position: relative;
       margin-right: 56px;
       img{
         width: 100%;
       }
       .p1{
         font-size: 20px;
         line-height: 26px;
         text-align: left;
         margin-top: 15px;
         padding: 0 20px;
         color: #333333;
       }
       .p2{
         font-size: 16px;
         color: #999999;
         text-align: right;
         margin-top: 20px;
         margin-bottom: 21px;
         padding: 0 20px;
       }
    }
    .item:nth-child(2n+0){
      margin-right: 0;
    }
  }
  .item_tit{
    padding: 7px 17px;
    border-radius: 5px;
    background: rgba(4,4,15,.45);
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .item-img-box{
    width: 100%;
    height: 281px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  @keyframes myfirst
{
0%   {top: 0;}
50%  {top: calc(100% - 670px);}
100% {top: 0;}
}
</style>

